<template>
  <div>
    <v-toolbar tile flat elevation="1">
      <v-btn @click="$router.go(-1)" class="no-active" icon>
        <v-icon>$vuetify.icons.arrow_left</v-icon>
      </v-btn>
      <v-toolbar-title>Заявление</v-toolbar-title>
      <v-spacer></v-spacer>
    </v-toolbar>

    <v-container>
      <v-row>
        <v-col class="text-left" cols="12">
          <v-card v-if="!loadPage && (!statement || !statement.document)" class="doc-menu-card pa-4 mb-8">
            <!-- <v-card-title>Вы не можете загрузить заявление</v-card-title> -->
            <div class="statement-info">
              <div v-if="!commerceStatus" class="document-errors">
                <!-- <h3>Необходимо исправить текущие ошибки</h3> -->
                <h3 class="mb-2">Необходимо заполнить данные</h3>
                <!-- <div
                  class="error--text doc-error"
                  v-for="(error, index) in errors"
                  :key="index"
                >
                  {{ error }}
                </div> -->
                <!-- <v-btn
                  :to="{ name: 'CabinetSettingsAuthor' }"
                  class="mt-4"
                  dark
                  color="primary"
                  >Настройки профиля</v-btn
                > -->
                <settings-author-form @update-form="updateAuthor">
                </settings-author-form>
              </div>

              <div v-if="commerceStatus">
                <div class="status">
                  <b>Статус:</b>
                  <span class="error--text"> Не загружен</span>
                </div>
                <!-- <v-btn v-if="docTemplate" :href="docTemplate" target="_blank" class="mt-4" dark color="primary">Скачать заявление</v-btn> -->

                <v-btn
                  @click="downloadPdfTemplate"
                  class="mt-4"
                  dark
                  color="primary"
                  :loading="templateLoading"
                  >Скачать заявление</v-btn
                >

                <div class="instruction mt-4" v-html="instruction"></div>

                <v-form v-model="valid" ref="form">
                  <v-file-input
                    v-model="fileStatement"
                    clearable
                    :loading="loadingStatement"
                    :disabled="disabledStatement"
                    accept="image/png, image/jpeg, image/bmp"
                    :rules="statementRules"
                    :success-messages="successStatement"
                    label="Загрузить файл"
                  ></v-file-input>
                  <v-btn dark color="primary" @click="uploadeStatement"
                    >Отправить</v-btn
                  >
                </v-form>
              </div>
            </div>
          </v-card>

          <v-card v-if="statement && statement.document" class="doc-menu-card pa-4">
            <!-- <v-card-title>{{ title }} </v-card-title> -->
            <div class="statement-info">
              <div class="date"><b>Добавлен:</b> {{ statement.date_format }}</div>
              <div class="status">
                <b>Статус:</b>
                <span class="success--text"> {{ statement.status_text }}</span>
              </div>
              <div class="d-flex">
                <v-btn
                  @click="downloadImageStatement"
                  class="mt-4"
                  dark
                  color="primary"
                  >Скачать</v-btn
                >
                <v-spacer></v-spacer>
                <v-btn
                  v-if="!+statement.status"
                  @click="dellStatement"
                  class="mt-4"
                  dark
                  color="primary"
                  :loading="dellLoading"
                  >Удалить</v-btn
                >
              </div>
            </div>
            <div class="file_preview_box">
              <img
                :src="'data:image/jpg;base64,' + statement.image64"
                width="100%"
                alt=""
              />
            </div>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>
<script>
import { mapGetters } from "vuex";

import SettingsAuthorForm from "../SettingsAuthorForm.vue";
import exportFile from "@/utils/exportFile";
export default {
  components: { SettingsAuthorForm },
  data: () => ({
    slug: "",
    valid: true,
    requiredRules: [(v) => !!v || "Поле обязательно"],
    statementRules: [
      (v) => !v || v.size < 2000000 || "Рзмер файла не должен превышать 2 MB!",
    ],
    fileStatement: null,
    loadingStatement: false,
    disabledStatement: false,
    successStatement: "",
    templateLoading: false,
    dellLoading: false,
  }),
  methods: {
    updateAuthor(){
      this.getStatementStart();
    },
    getStatementStart() {
      this.$store.dispatch("documents/getStatementStart");
    },

    uploadeStatement() {
      // let input = event.target;
      // if (!input.files || !input.files[0]) {
      //   return;
      // }
      // this.fileDocx = input.files[0];
      this.loadingStatement = true;
      this.disabledStatement = true;
      this.successStatement = "";
      // let app = this;
      let formData = new FormData();
      formData.append("file", this.fileStatement);
      formData.append("_method", "PUT"); //костыль для laravel
      let app = this;

      this.$store
        .dispatch("documents/uploadStatementFile", formData)
        .then(() => {
          app.successStatement =
            "Данные успешно добавлены, ожидайте подтверждения!";
          app.clearStatementFile();
        })
        .catch(() => {
          app.clearStatementFile();
        });
    },
    clearStatementFile() {
      this.fileStatement = null;
      this.loadingStatement = false;
      this.disabledStatement = false;
    },
    downloadPdfTemplate() {
      let app = this;
      app.templateLoading = true;
      this.$store
        .dispatch("documents/getStatementTemplate")
        .then(() => {
          exportFile.downLoadPdf(this.docTemplate, "Заявление");
          app.templateLoading = false;
        })
        .catch(() => {
          app.templateLoading = false;
        });
    },
    downloadImageStatement() {
      exportFile.downLoadImg(this.statement.image64, "Договор");
    },
    dellStatement(){
      let app = this;
      app.successStatement = "";
      app.dellLoading = true;
      this.$store
        .dispatch("documents/dellStatement")
        .then(() => {
          app.dellLoading = false;
        })
        .catch(() => {
          app.dellLoading = false;
        });
    }
  },
  computed: {
    ...mapGetters({
      currentUser: "currentUser",
      loadPage: "loadPage",
      statement: "documents/statement",
      docTemplate: "documents/docTemplate",
      instruction: "documents/instruction",
      commerceStatus: "documents/commerceStatus",
      errors: "documents/errors",
      displayStep: "documents/displayStep",
    }),
    title() {
      let title = "Документы";
      if (this.slug == "sell") {
        title = "Документы продажи";
      } else if (this.slug == "sub") {
        title = "Документы подписки";
      }

      return title;
    },
  },
  mounted() {
    this.slug = this.$route.params.slug ? this.$route.params.slug : "sell";
    this.getStatementStart();
  },
};
</script>
