import FileSaver from "file-saver";
export default class fileSave {
    /**
           * Export Excel file
           * @param {*} res file stream
           * @param {*} name file name
     */
    static getExcel(res, name) {
        let blob = new Blob([this.convertRes(res)], {
            type: "application/vnd.ms-excel"
        });
        FileSaver.saveAs(blob, name + ".xlsx");
    }
 
    /**
           * Export CSV file
           * @param {*} res file stream
           * @param {*} name file name
     */
    static getCsv(res, name) {
        let blob = new Blob([this.convertRes(res)], {
            type: "application/vnd.ms-excel"
        });
        FileSaver.saveAs(blob, name + ".csv");
    }
 
    /**
           * Export picture 1
           * @param {*} url picture address
           * @param {*} name file name
     */
    static getImgURLs(url, name) {
        let last = url.substring(url.lastIndexOf("."), url.length);
        FileSaver.saveAs(url, `${name}${last}`);
    }
     /**
           * Export picture 2
           * @param {*} res file stream
           * @param {*} name file name
     */
    static downLoadImg(res, filename) {
        
        let blob = new Blob([this.convertRes(res)], {
            type: "image/jpeg"
        });
        FileSaver.saveAs(blob, `${filename}.jpg`);
    }
     /**
           * Export pdf 
           * @param {*} res file stream
           * @param {*} name file name
     */
    static downLoadPdf(res, filename) {
        
        let blob = new Blob([this.convertRes(res)], {
            type: "application/pdf"
        });
        FileSaver.saveAs(blob, `${filename}.pdf`);
    }

    static convertRes(res){
      var byteCharacters = atob(res);
      var byteNumbers = new Array(byteCharacters.length);
      for (var i = 0; i < byteCharacters.length; i++) {
          byteNumbers[i] = byteCharacters.charCodeAt(i);
      }
      var byteArray = new Uint8Array(byteNumbers);
      return byteArray;
    }
}